import React from 'react';
import ReactDOM from 'react-dom';
import BirthdayAge from './BirthdayAge';
import RentalLogicComponent from './EquipmentRentals'; // Pfad zu deiner Komponente

// Funktion zum Rendern des Geburtstags-Widgets
function renderBirthdayComponent() {
  ReactDOM.render(<BirthdayAge />, document.getElementById('react-birthday'));
}

// Funktion zum Rendern der Rental-Logic-Komponente
function renderRentalComponent() {
  ReactDOM.render(<RentalLogicComponent />, document.getElementById('react-rental'));
}

// Initiales Rendern
// renderBirthdayComponent();
renderRentalComponent();

// Event Listener für das Geburtstags-Input-Feld
const birthdayElement = document.getElementById('geburtstag');
if (birthdayElement) {
//   birthdayElement.addEventListener('input', renderBirthdayComponent);
  birthdayElement.addEventListener('input', renderRentalComponent);
}
