import React, { useState, useEffect, useRef } from 'react';
import { calculateAgeFromYear } from './utils'; // Importiere die Funktion


const EquipmentRentals = () => {
  // const [isSkiChecked, setIsSkiChecked] = useState(false);
  // const [isBoardChecked, setIsBoardChecked] = useState(false);
  // const [isSledChecked, setIsSledChecked] = useState(false);
  // const [isShoesChecked, setIsShoesChecked] = useState(false);
  // const [isHelmetChecked, setIsHelmetChecked] = useState(false);
  // const [isPaymentFeeChecked, setisPaymentFeeChecked] = useState(false);
  // const [isDiscountChecked, setIsDiscountChecked] = useState(false);
  // const [isLatefeeChecked, setIsLatefeeChecked] = useState(false);

  const [lng, setLng] = useState("de");

  const [isSkiChecked, setIsSkiChecked] = useState(() => {
    const skiInput = document.getElementById('rentski');
    return skiInput ? skiInput.checked : false;
  });

  const [isBoardChecked, setIsBoardChecked] = useState(() => {
    const boardInput = document.getElementById('rentboard');
    return boardInput ? boardInput.checked : false;
  });

  const [isSledChecked, setIsSledChecked] = useState(() => {
    const sledInput = document.getElementById('rentsled');
    return sledInput ? sledInput.checked : false;
  });

  const [isShoesChecked, setIsShoesChecked] = useState(() => {
    const shoesInput = document.getElementById('rentshoes');
    return shoesInput ? shoesInput.checked : false;
  });
  
  const [isHelmetChecked, setIsHelmetChecked] = useState(() => {
    const helmetInput = document.getElementById('renthelmet');
    return helmetInput ? helmetInput.checked : false;
  });
  
  const [isPaymentFeeChecked, setIsPaymentFeeChecked] = useState(() => {
    const paymentFeeInput = document.getElementById('bearbeitungsgebuehr');
    return paymentFeeInput ? paymentFeeInput.checked : false;
  });
  
  const [isDiscountChecked, setIsDiscountChecked] = useState(() => {
    const discountInput = document.getElementById('rabatt');
    return discountInput ? discountInput.checked : false;
  });
  
  const [isLatefeeChecked, setIsLatefeeChecked] = useState(() => {
    const latefeeInput = document.getElementById('rentlatefee');
    return latefeeInput ? latefeeInput.checked : false;
  });
  

  const [age_yob, setAge_yob] = useState(0); // Set initial age to empty string
  const birthdayInputRef = useRef(null);
  
 
  useEffect(() => {
    const birthdayInput = document.getElementById('geburtstag');

    // Define the event handler
    const handleInput = (event) => {
      setAge_yob(calculateAgeFromYear(event.target.value));
      console.log(calculateAgeFromYear(event.target.value));
    };

    if (birthdayInput) {
      birthdayInput.addEventListener('input', handleInput);
    }

    // Clean up the event listener on component unmount
    return () => {
      if (birthdayInput) {
        birthdayInput.removeEventListener('input', handleInput);
      }
    };
  }, []);



  const handleCheckboxChange = (setter) => (event) => {
    setter(event.target.checked);
  };

  useEffect(() => {
    const skiInput = document.getElementById('rentski');
    const boardInput = document.getElementById('rentboard');
    const sledInput = document.getElementById('rentsled');
    const shoesInput = document.getElementById('rentshoes');
    const helmetInput = document.getElementById('renthelmet');

    const paymentFee = document.getElementById('bearbeitungsgebuehr');
    const discount = document.getElementById('rabatt');
    const latefee = document.getElementById('lateFee');

    if (skiInput) skiInput.addEventListener('change', handleCheckboxChange(setIsSkiChecked));
    if (boardInput) boardInput.addEventListener('change', handleCheckboxChange(setIsBoardChecked));
    if (sledInput) sledInput.addEventListener('change', handleCheckboxChange(setIsSledChecked));
    if (shoesInput) shoesInput.addEventListener('change', handleCheckboxChange(setIsShoesChecked));
    if (helmetInput) helmetInput.addEventListener('change', handleCheckboxChange(setIsHelmetChecked));
    if (paymentFee) paymentFee.addEventListener('change', handleCheckboxChange(setIsPaymentFeeChecked));
    if (discount) discount.addEventListener('change', handleCheckboxChange(setIsDiscountChecked));
    if (latefee) latefee.addEventListener('change', handleCheckboxChange(setIsLatefeeChecked));

   return () => {
      if (skiInput) skiInput.removeEventListener('change', handleCheckboxChange(setIsSkiChecked));
      if (boardInput) boardInput.removeEventListener('change', handleCheckboxChange(setIsBoardChecked));
      if (sledInput) sledInput.removeEventListener('change', handleCheckboxChange(setIsSledChecked));
      if (shoesInput) shoesInput.removeEventListener('change', handleCheckboxChange(setIsShoesChecked));
      if (helmetInput) helmetInput.removeEventListener('change', handleCheckboxChange(setIsHelmetChecked));
      if (paymentFee) paymentFee.removeEventListener('change', handleCheckboxChange(setIsPaymentFeeChecked));
      if (discount) discount.removeEventListener('change', handleCheckboxChange(setIsDiscountChecked));
      if (latefee) latefee.removeEventListener('change', handleCheckboxChange(setIsDiscountChecked));

    }; 
  }, []);


    const skiInput = document.getElementById('rentski');
    const boardInput = document.getElementById('rentboard');
    const sledInput = document.getElementById('rentsled');

  const [betragkurs, setBetragkurs] = useState(0);  // State to store betragkurs
  const [betragski, setBetragski] = useState(0);
  const [betragboard, setBetragboard] = useState(0);
  const [betragsled, setBetragsled] = useState(0);
  const [betraghelmet, setBetraghelmet] = useState(0);
  const [betragshoes, setBetragshoes] = useState(0);
  const [betragdiscount, setBetragdiscount] = useState(0);
  const [betragPaymentFee, setbetragPaymentFee] = useState(0);
  const [betragspaet, setBetragspaet] =useState(0);

  
  const [betragtotal, setBetragtotal] = useState(0);
  const [nameGear, setNameGear] = useState("Ski");
  const [anzTage, setAnzTage] = useState(0);



const formatToTwoDecimal = (value) => {
  return parseFloat(value).toFixed(2);
};

const [eventCode, setEventCode] = useState('');


// Set language based on URL
useEffect(() => {
  const urlStr = window.location.href;
  if (urlStr.includes('ENG')) {
    setLng("en");
  }
}, []);

  // Initial fetch for event code
  useEffect(() => {
    fetchEventCode();
  }, []);


 // Function to fetch event code from HTML element
 async function fetchEventCode() {
  const element = document.getElementById('eventcode');
  if (element) {
    const textContent = element.textContent || "";
    const match = textContent.match(/\(([^)]+)\)/); // Extracts the value inside parentheses
    if (match) {
      const codeFromHTML = match[1];
      setEventCode(codeFromHTML);
    }
  }
}

  // Fetch prices from API with a timeout
  useEffect(() => {
    const timer = setTimeout(async () => {
      if (eventCode === '') {
        await fetchEventCode(); // Ensure eventCode is fetched before proceeding
      }
      
      try {
        const response = await fetch(`/APP/veranstaltungen/getPricesAPI?lng=${lng}&age_yob=${age_yob}&code=${eventCode}`); // Append parameters to the URL
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        console.log(data);
        // Assume data contains an object with the relevant prices
        setBetragkurs(data.kursPrice);
        setBetragski(data.Ski);
        setBetragboard(data.Snowboard);
        setBetragsled(data.Schlitten);
        setBetragshoes(data.Schuhe);
        setBetraghelmet(data.Helm);

        setBetragdiscount(data.rabatt);
        setAnzTage(data.dauer);
        setbetragPaymentFee(data.Bearbeitungsgebuehr);
        setBetragspaet(data.latefee);

      } catch (error) {
        console.error('Error fetching prices:', error);
      }
    }, 100); // 100ms timeout

    // Cleanup function to clear the timeout if the component unmounts or dependencies change
    return () => clearTimeout(timer);
  }, [eventCode, age_yob, lng, window.location.href]);

const [highlight, setHighlight] = useState(false);

useEffect(() => {
  if (betragtotal !== betragkurs) {
    // Trigger the highlight effect when betragtotal is not equal to betragkurs
    setHighlight(true);

    // Remove the highlight after 500ms
    const timer = setTimeout(() => {
      setHighlight(false);
    }, 200);

    // Cleanup the timer if the component unmounts or betragtotal/betragkurs change
    return () => clearTimeout(timer);
  }
}, [betragtotal, betragkurs]);

useEffect(() => {
  let total = betragkurs;

  if (isSkiChecked) total += betragski;
  if (isBoardChecked) total += betragboard;
  if (isSledChecked) total += betragsled;
  if (isShoesChecked) total += betragshoes;
  if (isHelmetChecked) total += betraghelmet;

  if (isPaymentFeeChecked) total += betragPaymentFee;
  if (isLatefeeChecked) total += betragspaet;

  if (isDiscountChecked) total -= betragdiscount;

  setBetragtotal(total);

  setNameGear(age_yob > 14 ? 'Ski Erwachsen' : 'Ski Kind');
}, [
  betragkurs, betragspaet, betragski, betragboard, betragshoes,
  betraghelmet, betragdiscount, betragPaymentFee, isSkiChecked,
  isBoardChecked, isSledChecked, isShoesChecked, isHelmetChecked,
  isDiscountChecked, isPaymentFeeChecked, isLatefeeChecked, age_yob
]);

    // This effect handles the calculation and updates for `betragski`
    useEffect(() => {
      if (isSkiChecked) {
        setIsBoardChecked(false);
      if (boardInput) boardInput.checked = false;
      if (sledInput) sledInput.checked = false;
      }

    }, [isSkiChecked]);

    useEffect(() => {
    if (isBoardChecked) 
      {
      setIsSkiChecked(false);
      setIsSledChecked(false);
    if (skiInput) skiInput.checked = false;
    if (sledInput) sledInput.checked = false;
    }
  }, [isBoardChecked]);

  useEffect(() => {
    if (isSledChecked)
    {
      setIsSkiChecked(false);
      setIsBoardChecked(false);
    if (boardInput) boardInput.checked = false;
    if (skiInput) skiInput.checked = false;
    }
  }, [isSledChecked]);


  const AdditionalItem = ({ isChecked, itemName, itemPrice, itemIcon }) => {
    const itemNames = {
      en: {
        Ski: 'Ski',
        Board: 'Board',
        Sled: 'Sled',
        Shoes: 'Shoes',
        Helmet: 'Helmet',
        PaymentFee: 'Fee',
        Discount: 'Discount'
      },
      de: {
        Ski: 'Ski',
        Board: 'Board',
        Sled: 'Schlitten',
        Shoes: 'Schuhe',
        Helmet: 'Helm',
        PaymentFee: 'Gebühr',
        Discount: 'Rabatt'
      }
      // Add more languages if needed
    };
  
    const translatedName = itemNames[lng][itemName] || itemName;

    return (
      isChecked && (
        <p className="cyan-text accent-4-text meta-part">
          <i className="small material-icons left">{itemIcon}</i> 
          {itemName === 'Discount' ? '-' : '+'} &emsp;
          {formatToTwoDecimal(itemPrice)} € &emsp; &emsp;{translatedName} 
        </p>
      )
    );
  };
  

  return (

    <div>
<AdditionalItem  
  isChecked={isPaymentFeeChecked} 
  itemName="PaymentFee" 
  itemPrice={betragPaymentFee} 
  lng={lng}
  itemIcon="account_balance"
/>
<AdditionalItem  
  isChecked={isSkiChecked} 
  itemName="Ski" 
  itemPrice={betragski} 
  lng={lng}
  itemIcon="ac_unit"
/>
<AdditionalItem 
  isChecked={isBoardChecked} 
  itemName="Board" 
  itemPrice={betragboard} 
  lng={lng}
  itemIcon="ac_unit"
/>
<AdditionalItem 
  isChecked={isShoesChecked} 
  itemName="Shoes" 
  itemPrice={betragshoes} 
  lng={lng}
  itemIcon="ac_unit"
/>
<AdditionalItem 
  isChecked={isHelmetChecked} 
  itemName="Helmet" 
  itemPrice={betraghelmet} 
  lng={lng}
  itemIcon="ac_unit"
/>
<AdditionalItem  
  isChecked={isDiscountChecked} 
  itemName="Discount" 
  itemPrice={betragdiscount} 
  lng={lng}
  itemIcon="card_giftcard"
/>
      <p className={`accent-4-text meta-part react_betragtotal`}
            style={{ color: highlight ? 'transparent' : '#1cbad2', transition: 'color 0.2s ease' }}>

  {betragtotal !== betragkurs ? (
    <><hr /><i 
    className="small material-icons left">attach_money</i> &emsp; &emsp; <strong>{formatToTwoDecimal(betragtotal)} €</strong></>) : ('')}
    </p>
    </div>
    
  );
};

export default EquipmentRentals;
