// calculateAge.js
export function calculateAge(birthdate) {
    if (!birthdate) return 'Invalid date';
  
    const today = new Date();
    const [day, month, year] = birthdate.split('.').map(num => parseInt(num, 10));
    const birthDate = new Date(year, month - 1, day); // JS months are 0-based
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
  
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
  
    return age;
  }

  export function calculateAgeFromYear(birthdate) {
    if (!birthdate) return 'Invalid date';
  
    const [day, month, year] = birthdate.split('.').map(num => parseInt(num, 10));
    const currentYear = new Date().getFullYear();
    let age = currentYear - year;
  
    return age;
  }
  
  